<template>
  <div class="page-503">
    <img :src="require('@/assets/500.png')"
         alt="" />
    <div class="info">嗯～这个…服务器日常抽风…</div>
    <div>
      <van-button block
                  @click="toOldUrl"
                  plain
                  round
                  type="info">刷新</van-button>
    </div>
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  components: {
    [Button.name]: Button,
  },
  data () {
    return {
      oldUrl: "/"
    };
  },
  computed: {},
  methods: {
    //跳转上一个页面
    toOldUrl () {
      if (this.defineMethods.isNullOrEmpty(this.oldUrl))
        this.oldUrl = "/";
      this.$router.push(this.oldUrl);
    }
  },
  mounted () {
    this.oldUrl = this.$route.query.toUrl;
  }
};
</script>
<style lang="scss" scoped>
.page-503 {
  text-align: center;
  color: #c4c4c4;
  .info {
    margin-top: 22px;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: 400;
  }
  > div:last-child {
    width: 100px;
    margin: 0 auto;
  }
}
.van-button {
  border-radius: 999px;
}
</style>